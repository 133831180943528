import { useState, useEffect } from "react";
import axios from "axios";
import { API_LINK } from "../utils/ApiEndPoints/constants";

//getting company names
const useCompanyNames = () => {
     console.log("apilink: ",API_LINK);
    const [companyNames, setCompanyNames] = useState([]);

    useEffect(() => {
        axios.get(`${API_LINK}/companies`)
            .then(response => {
                setCompanyNames(response.data);
            })
            .catch(error => {
                console.error("Error fetching contacts:", error);
            })
    }, []);
    return companyNames;
}

export default useCompanyNames;
