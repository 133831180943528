import React, { useEffect, useState} from 'react';
import Diagram, { CustomShape, Group, Toolbox, MainToolbar, Command, HistoryToolbar} from 'devextreme-react/diagram';
import { confirm, alert, } from 'devextreme/ui/dialog';
import saveDiagramData from '../customHooks/useSaveDiagram';
import axios from 'axios';
import CompaniesDialogBox from './companiesDialogBox';
import { LoadPanel } from 'devextreme-react/load-panel';
import fetchData from '../customHooks/useFetchDiagram';
import { API_LINK } from './ApiEndPoints/constants';

// for creating diagram display chart
function CustomShapeComponent({ employees, diagramRef, companyNames}) {
  
  const [companyContacts, setCompanyContacts] = useState([]);
  console.log("companyContactsList", companyContacts);
  const [open, setOpen] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  console.log("companyList-------------------"+companyList);
  companyList.map((data)=>{
    console.log("data1234 "+data.name);
    console.log("data1234 "+typeof(data.name));
  })
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState('');
  console.log("selectedCompany(customShapeComponent.js())", selectedCompany);
  console.log("CompanyNames in customShapeComponent.js:", companyNames.length);

  useEffect(() => { 
    if (companyNames.length !== 0) {
      setLoading(false);
    }
  }, [companyNames]);


  const handleClickOpen = () => {
    setOpen(true);
  }
  

  function toLowerCaseString(input) {
    return input.toLowerCase();
  }

  const handleCompanyOrg = (company)=>{
     setSelectedCompany(company);
     fetchCompanyContacts(company);
     fetchData(diagramRef, company);
  }


  const fetchCompanyContacts = (companyName) => {

    axios.get(`${API_LINK}/contacts/by/company/${companyName}`)
      .then(response => {
        setCompanyContacts(response.data);
      })
      .catch(error => {
        console.error("Error fetching contacts:", error);
      })

  }
  const fetchSavedCompanies = () => {

    axios.get(`${API_LINK}/get/companies/for/user`)
      .then(response => {
        console.log("savedCompanies OrgCharts ", response.data);
        setCompanyList(response.data);
      })
      .catch(error => {
        console.error("Error fetching contacts:", error);
      })
  }
  const deleteDiagramDataFromDB = (company) => {

    axios.delete(`${API_LINK}/deletediagram/${company}`)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        alert("Error deleting company orgchart:", error);
      })
  }

  const onCustomCommand = (e) => {
    const diagram = diagramRef.current.instance;
    const currentDiagramData = diagram.export();
    const currentDiagramDataJson = JSON.parse(currentDiagramData);
    if (e.name === 'clear' && !(selectedCompany == null || selectedCompany === '' || selectedCompany === 'All Contacts')&&currentDiagramDataJson.shapes.length > 0) {
      const result = confirm('Are you sure you want to clear the diagram? This action cannot be undone.', 'Warning');
      result.then(
        (dialogResult) => {
          if (dialogResult) {
            e.component.import('');
            deleteDiagramDataFromDB(selectedCompany);
          }
        },
      );
    } else if (e.name === 'companiesList') {
      // Handle custom command logic
      setCompanyList(companyNames);
      handleClickOpen()
      console.log('Custom command executed!');
    } else if (e.name === 'savedOrgCharts') {
      fetchSavedCompanies();
      handleClickOpen()
    }
  };

  return (
    <div className='diagramContainer'>
      <div className='nav'>
        <h3>NextOrg</h3>
      </div>
      <Diagram id="diagram" ref={diagramRef} showGrid={false} simpleView={true} onCustomCommand={onCustomCommand} defaultItemProperties={{
        connectorLineEnd: 'none',}}  >
        {companyContacts.map((contact, contactIndex) => (
          <CustomShape
            category={toLowerCaseString(contact.company)}
            type={`contact${contact.id}`}
            baseType="cardWithImageOnLeft"
            defaultText={`${contact.firstName} ${contact.lastName} \n \n \n${contact.company}\n ${contact.email}\n${contact.jobTitle}`}
            allowHtml={true}
            allowEditText={true}
            textWidth={1}
            defaultWidth={3}
            defaultHeight={1}
            allowResize={true}
            keepRatioOnAutoSize={true}
            key={contactIndex}
          />
        ))}
        {employees.map((employee, index) => (
          <CustomShape
            category="employees"
            type={`employee${employee.id}`}
            baseType="cardWithImageOnLeft"
            defaultText={`${employee.firstName} ${employee.lastName}\n\n\n${employee.company}\n${employee.email}\n${employee.jobTitle}`}
            allowHtml={true}
            allowEditText={true}
            textWidth={1}
            defaultWidth={3}
            defaultHeight={1}
            // allowResize={true}
            keepRatioOnAutoSize={true}
            key={index}
          />
        ))}
        <Toolbox width={350} id='toolbox' key={selectedCompany}>
          {(selectedCompany == null || selectedCompany === '' || selectedCompany === 'All Contacts') ? (
            <Group
              category="employees"
              title="All Contacts"
              displayMode="texts"
              expanded={true}
            />
          ) : (
            <Group
              key={selectedCompany}
              category={selectedCompany}
              title={selectedCompany}
              displayMode="texts"
              expanded={true}
            />
          )}
        </Toolbox>
        <MainToolbar visible={true}>
          <Command name="undo" />
          <Command name="redo" />
          <Command name="separator" />
          <Command name="fontName" />
          <Command name="fontSize" />
          <Command name="separator" />
          <Command name="bold" />
          <Command name="italic" />
          <Command name="underline" />
          <Command name="separator" />
          <Command name="fontColor" />
          <Command name="lineColor" />
          <Command name="fillColor" />
          <Command name="separator" />
          <Command name="clear" icon="clearsquare" text="Clear Diagram" />
          <Command name='save' text='save' icon="save" location='after'/>
          <Command name="companiesList" text="Companies List" icon='menu' location='after' />
          <Command name="savedOrgCharts" text="Saved OrgCharts" icon='chevrondown' location='after' />
        </MainToolbar>
        <HistoryToolbar visible={false} />

        <CompaniesDialogBox companies={companyList} open={open} setOpen={setOpen}
         handleClickSelected = {handleCompanyOrg}/>

      </Diagram>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#diagram' }}
        visible={loading}
        showIndicator={true}
        showPane={false}
        shading={true}
      />
      <button onClick={() => saveDiagramData(diagramRef, selectedCompany)}>Save Org-Chart</button>
    </div>
  );
}
export default CustomShapeComponent;


