import axios from 'axios';
import { API_LINK } from '../utils/ApiEndPoints/constants';

//fetching diagram data from DB
const fetchData = async (diagramRef, selectedCompany) => {
  try {
    const response = await axios.get(`${API_LINK}/getdiagram/${selectedCompany}`);

    if (response.status === 200) {
      const retrievedDiagramData = response.data.data;
      console.log("retrieved from backend", retrievedDiagramData);
      const diagram = diagramRef.current.instance;
      diagram.import(retrievedDiagramData);
    }
  } catch (error) {
    console.error('Error retrieving diagram data:', error);
  }
};

export default fetchData;