import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import  Routing  from "./routes/routes.js" 

function App() {

  return <Routing />;
}
export default App;

