import { useState, useEffect } from "react";
import axios from "axios";
import { API_LINK } from "../utils/ApiEndPoints/constants";

//getting all contacts
const useData = () => {

    const [contacts, setContacts] = useState([]);
   useEffect(() => {

        axios.get(`${API_LINK}/contacts`)
            .then(response => {
                setContacts(response.data);
            })
            .catch(error => {
                console.error("Error fetching contacts:", error);
            })


    }, []);

    return contacts;
}


export default useData
