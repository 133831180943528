import React from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Signup from '../Components/Signup';
import Orgchart from '../Components/Orgchart';

//routing
const Routing = () => {
    return(
        <>
        <BrowserRouter>
        <Routes>
          <Route path='/' element ={<Signup/>}></Route>
          <Route path ='/home' element ={<Orgchart/>}></Route>
        </Routes>
        </BrowserRouter>
        </>
    );
    
}

export default Routing;