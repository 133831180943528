import axios from 'axios';
import { API_LINK } from '../utils/ApiEndPoints/constants';


const saveDiagramData = async (diagramRef, selectedCompany) => {

  // Get the current diagram data
  const diagram = diagramRef.current.instance;
  const currentDiagramData = diagram.export();
  const currentDiagramDataJson = JSON.parse(currentDiagramData);
  console.log("saved diagram data:", JSON.parse(currentDiagramData).shapes);

  try {
    if (currentDiagramDataJson.shapes.length > 0 && !(selectedCompany == null || selectedCompany === '' || selectedCompany === 'All Contacts')) {
      const requestData = {
        companyName: selectedCompany,
        data: currentDiagramData,
      };
      const response = await axios.post(`${API_LINK}/savediagram`, requestData);
      console.log("based on company", response.data);
      alert('Diagram data saved successfully!');
    }
  } catch (error) {
    alert('Error saving org-chart');
    console.error('Error saving diagram data:', error);
  }
}

export default saveDiagramData;