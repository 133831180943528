import React, { useState, useMemo} from "react";
import { Dialog } from "@mui/material";
import { Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../styles/companyDialogBox.css";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CompaniesDialogBox = ({companies, open, setOpen, handleClickSelected }) => {

  const [company, setCompany] = useState("");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;


  const handleClose = () => {
    setOpen(false);
  };

  const handleHover = (index) => {
    setHoveredRowIndex(index);
  };

  const handleLeave = () => {
    setHoveredRowIndex(null);
  };

  function toLowerCaseString(input) {
    return input.toLowerCase();
  }

  const handleShowOrgChart =  (companySel) => {
      handleClickSelected(toLowerCaseString(companySel));
      handleClose();
  }

  const filteredCompanies = useMemo(() => {
    return companies
      .map(company => company.name.toLowerCase()) // Convert each company name to lowercase
      .filter(name => name.includes(company.toLowerCase())); // Apply the search term filter
  }, [company, companies]);
  

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredCompanies.length / itemsPerPage);

  // Calculate the starting and ending indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Extract the companies for the current page
  const companiesForCurrentPage = filteredCompanies.slice(startIndex, endIndex);   //filteredCompanies.slice(startIndex, endIndex);
  filteredCompanies.map((comp)=>{
          console.log("filtered company : "+comp)
  })

  return (

    <div className="dialogModal">

      <Dialog open={open}
        TransitionComponent={Transition}
        // fullScreen
        onClose={handleClose}>
        {companies.length > 0 ? (
          <div>
            <div className="topContainer">
              <input
                type="text"
                placeholder="Enter the Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
              <CloseIcon onClick={handleClose} />
            </div>
            <table className="dialogTable">
              <tbody>
                {companiesForCurrentPage.map((companyName, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "20px 0px",
                        borderBottom: "1px solid gray",
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: 300,
                        color: "gray",
                      }}
                    >
                      {companyName}
                    </td>
                    <td
                      style={{ padding: "20px 0px", textAlign: "center", textDecoration: hoveredRowIndex === index ? "underline" : "none", color: hoveredRowIndex === index ? "greenyellow" : "gray", cursor: hoveredRowIndex === index ? "pointer" : "none" }}
                      onMouseEnter={() => handleHover(index)}
                      onMouseLeave={handleLeave}
                      onClick={() => handleShowOrgChart(companyName)}
                    >
                      Show Orgchart
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination controls */}
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {Array.from({ length: totalPages }).map((_, index) => (
                <span
                  key={index}
                  style={{
                    margin: "0 5px",
                    cursor: "pointer",
                    textDecoration: currentPage === index + 1 ? "underline" : "none",
                    color: currentPage === index + 1 ? "blue" : "black",
                  }}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </span>
              ))}
            </div>
          </div>
        ) : (<div style={{ height: "350px", width: "400px" }}>
          <CloseIcon style={{ paddingLeft: "365px" }} onClick={handleClose} />
          <p style={{ fontSize: "25px", textAlign: "center", margin: "20px 0px", color: "gray" }}>No Records Found</p>
        </div>)}
      </Dialog>
    </div>

  );
};

export default CompaniesDialogBox;