import React from "react";
import axios from "axios";

// Sign up page
function Signup() {

    //triggering HubSpot OAuth.
    const handleAuthorize = async () => {
        try {
            const response = await axios.get("https://orgchart.nextrow.com:8443/oauth/authorize");
            const redirectResponse = response.data;

            if (redirectResponse && redirectResponse.startsWith("redirect:")) {
                const redirectUrl = redirectResponse.replace("redirect:", "").trim();
                window.location.href = redirectUrl;
            } else if (response.data.includes("Redirecting to")) {
                // Extract the redirection URL
                const redirectUrl = response.data.replace("Redirecting to", "").trim();
                // Redirect to the specified URL
                window.location.href = redirectUrl;
            }

        } catch (error) {
            console.error("Error triggering authorization:", error);
        }
    }

    return (
        <div className="authorizeButton">
            <button
                onClick={handleAuthorize}
            >
                Get Started
            </button>
        </div>
    );
}

export default Signup;
