import React, {useRef} from 'react';
import CustomShapeComponent from '../utils/CustomShapeComponent';
import '../styles/customShape.css';
import "../styles/Nav.css"
import useCompanyNames from '../customHooks/useCompanyNames';
import useData from '../customHooks/useAllContactsData';


//orgchart page

const Orgchart = () => {
 
    const employees = useData();
    const companyNames = useCompanyNames();
   
     const diagramRef = useRef(null);
    if(!companyNames) return;
  
    return (

      <div>
        <CustomShapeComponent   employees={employees} diagramRef={diagramRef} companyNames={companyNames}/>
      </div>
    );
}
export default Orgchart;